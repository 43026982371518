@import "variables";
@import "../vendors/pacejs/minimal";


body,
html {
    font-family: 'Roboto', sans-serif;

    * {
        border-width: 2px !important;
    }
}


:root {
    --bs-border-width: 2px !important;
    --bs-border-color: #D0D5D8 !important;
}


.label {
    font-size: 16px;
    font-weight: 500;
}

.form-control {
    padding: 5px 0;
    border: none;
}


input {
    &:disabled {
        background-color: transparent !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}